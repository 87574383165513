export const Privacy = () => {
  return (
    <div id='privacy'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>Privacy</h2>
        </div>
        <div>
          <h2>Privacy Policy</h2>
        </div>
        <div>
          <h3>Our Mission</h3>
        </div>
        <div>
          <p>We are sensitive to the private nature of the information you, our service providers, partners, end-users and prospective end-user customers provide to us. Our Privacy Policy is designed to maximize your ability to protect your personal, private data while at the same time allowing us to provide you with the best interactive security, energy management, video monitoring, automation and wellness services possible. This Privacy Policy states our privacy practices for your use of (i) this website and any mobile sites or applications operated by OGN Tech (the “User Interfaces”) and (ii) any combination of products and services we provide or that can be purchased or accessed via any User Interface (the “Services”). </p>
        </div>
        <div>
          <h3>Information Collection and Use</h3>
        </div>
        <div>
          <p>OGN Tech collects and stores certain information from or about you that can be used to uniquely contact, identify, or locate you ("Personal Information").</p>
          <p>When using the User Interfaces, you may provide us with Personal Information including, but not limited to, your name, home or business address, phone number, email address, location, or zip code. </p>
          <p>Any personal information you provide to us will only be used for the purpose it was provided. We will not reproduce, sell, trade or willfully expose your personal information without your consent unless instructed to by a court of law. While we strive to ensure the security of your personal information you should also be aware that no method of data transmission via the internet, or method of data storage is 100% secure. As such we can not guarantee the absolute security of any personal information you provide to us.</p>
        </div>
        <div>
          <h3>Location-based Information</h3>
        </div>
        <div>
          <p>Our health watches collects your GPS location and stores in our secured database. The location will only be shared to users whom you have approved to view. OGN Tech won't share you location to any other providers or use it internally.</p>
        </div>
        <div>
          <h3>Privacy Policy Changes</h3>
        </div>
        <div>
          <p>We may change this Privacy Policy at any time. Any changes to this Privacy Policy will be posted on our User Interfaces. This Privacy Policy is not intended to and does not create any contractual or other legal right in or on behalf of any party.</p>
        </div>
        <div>
          <h3>Contact Information</h3>
        </div>
        <div>
          <p>If you have any questions, concerns, or suggestions regarding this Privacy Policy, please contact us at:</p>
          <p>privacy@ogn.co.nz</p>
        </div>
        {/* <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  <div className='testimonial'>
                    <div className='testimonial-image'>
                      {' '}
                      <img src={d.img} alt='' />{' '}
                    </div>
                    <div className='testimonial-content'>
                      <p>"{d.text}"</p>
                      <div className='testimonial-meta'> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
        </div> */}
      </div>
    </div>
  )
}
